import { mdiAccount, mdiCart, mdiCreditCard, mdiHomeOutline, mdiWallet, mdiInbox, mdiFilePdf } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },
  {
    title: 'Requests',
    icon: mdiAccount,
    to: 'members',
  },
  {
    title: 'Cashiering',
    icon: mdiCart,
    to: 'cashiering',
  },
  {
    title: 'Stocks',
    icon: mdiInbox,
    to: 'stocks',
  },
  {
    title: 'Report',
    icon: mdiFilePdf,
    to: 'report',
  },
  {
    subheader: 'EARNINGS',
  },
  {
    title: 'Rebates',
    icon: mdiCreditCard,
    to: 'rebates',
  },
  {
    title: 'Wallet',
    icon: mdiWallet,
    to: 'wallet',
  },

]
